body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 800px;
  height: 100%; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin: 0 auto;
  font-size: 1.2rem;
}

.flex-line {
  display: flex;
  width: 800px;
}

.imageWrapper {
  /* width: 800px; */
  /* height: 100%;  */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin: 10px auto;
  display: flex;
}

.float {
  text-align: left;
  padding-left: 1rem;
}

.search {
  font-size: 2rem;
  margin: 0 auto;
  width: 800px;
  padding: 0.2rem;
  margin-bottom: 0.5rem;
}

.exifSelector {
  text-align: left;
  padding: 1rem;
  width: 50%;
}

p {
  text-align: left;
}

input, select {
  font-size: 1.2rem;
  padding: 0.5rem;
}

input {
  margin: 0.2rem;
}

select {
  margin-top: 0.75rem;
}

.number-input {
  width: 3rem;
}

button {
  font-size: 1.5rem;
  padding: 0.5rem;
}
